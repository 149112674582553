import React from "react";

import Layout from "../../Components/Layout";
import Otp from "../../Components/Otp";

const Index = ({ userId }) => {
  return (
    <Layout showHeader>
      <Otp userId={userId} />
    </Layout>
  );
};

export default Index;
