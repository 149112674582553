import React, { useEffect, useState } from "react";

import { navigate } from "gatsby";
import { useLazyQuery } from "@apollo/client";
import { useForm, SubmitHandler } from "react-hook-form";
import { useState as useHookstate } from "@hookstate/core";

import OtpImg from "../../images/otp_icon@2x.png";
import { sendOtp, verifyOtp } from "../../utils/Login";

import * as OtpStyle from "./Otp.module.scss";
import { Login } from "../../utils/Auth";
import { getUserQuery } from "../../Query/User";
import { User } from "../../Interfaces/User";
import AppState from "../../States/AppState";

interface Props {
  userId: string;
}

const Index = ({ userId }: Props) => {
  const appState = useHookstate(AppState);
  const [getUser, { data, loading, error }] =
    useLazyQuery<{ user: User }>(getUserQuery);
  const [otpId, setOtpId] = useState<number>();
  const { register, handleSubmit } = useForm();

  useEffect(() => {
    sendOtp(userId)
      .then((res) => {
        setOtpId(res.id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onHandleSubmit: SubmitHandler<{ otp: number }> = ({ otp }) => {
    if (otpId) {
      verifyOtp(otpId, otp)
        .then((response) => {
          Login(response).then((userId) => {
            getUser({
              variables: {
                id: userId,
              },
            }).then((res) => {
              appState.user.set({ ...res.data?.user });
              navigate("/");
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <form
      className={OtpStyle.container}
      onSubmit={handleSubmit(onHandleSubmit)}
    >
      <img
        className="align-self-center"
        src={OtpImg}
        alt=""
        width={56}
        height={56}
      />
      <span className="boldText color-primary textMd align-self-center">
        Enter OTP
      </span>
      <div className={OtpStyle.otp}>
        <input
          className="input text-center"
          type="text"
          placeholder="Enter SMS OTP"
          {...register("otp")}
        />
        <button className={OtpStyle.otp__resend}>Resend Otp</button>
      </div>
      <div className={OtpStyle.actionBtn}>
        <button
          onClick={() => navigate("/signup")}
          className="btn btn__secondary"
        >
          Back
        </button>
        <button type="submit" className="btn btn__primary">
          Send
        </button>
      </div>
    </form>
  );
};

export default Index;
