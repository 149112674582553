import Server from "./Server";

export const sendOtp = (userId: string): Promise<{ id: number }> => {
  return new Promise((resolve, reject) => {
    Server.post("/login/otp", {
      user: {
        id: `${userId}`,
      },
    })
      .then((res: any) => {
        return resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const verifyOtp = (
  otpId: number,
  otp: number
): Promise<{ token: string; refreshToken: string }> => {
  return new Promise((resolve, reject) => {
    Server.post(`/login/token`, {
      id: otpId,
      otp: otp,
    })
      .then((res: any) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
